/** AUTH LOGIN  */

.brandAuth {
    display: block;
    height: 5rem !important;
    width: 10rem;
}
.authLoding {
    width: 100%;
    height: 100%;
    position: absolute;
    aspect-ratio: unset;
    z-index: 1;
}

.cardLogin {
    z-index: 5;
}

.footer a {
    color: white !important;
}

.welcome {
    position: relative;
    z-index: 5;
}

.inputs {
    all: unset;
    display: block;
    width: 89%;
}

/** CLIENTES */

.routeLink {
    display: flex;
    align-items: center;
}

.routeLink li {
    list-style: none;
}

.routeLink li a {
    cursor: pointer;
}

.content {
    margin-top: 6rem;
}

.navbarContainer {
    margin-top: -5.5rem;
}

.clientsHeader,
.clientsSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.clientsSection div {
    flex-basis: 22rem;
    display: flex;
}

.clientsSection div button {
    flex-basis: 11rem;
}

.btCreate {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 20rem;
    background-color: #def1fd;
}

.btCreate:hover {
    background-color: none;
    background-color: #def1fd;
}

.btCreate:active {
    background-color: #d2e3ee;
}

.clientsH2 {
    margin: 2rem 0;
}
.tableSpacing {
    margin: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
}

/*? filter input  */

.inputFilters {
    display: flex;
    column-gap: 0.5rem;
    flex-wrap: wrap;
}

.inputFilters form {
    width: 100%;
    flex-basis: 32%;
    flex-grow: 1;
}

.inputContainer {
    display: block;
    width: 100% !important;
    align-items: center;
}

.inputFilters form button {
    all: unset;
    cursor: pointer;
}

.inputSearch {
    border: 1px solid rgb(186, 185, 185);
    border-radius: 5px;
    height: 3rem;
    max-width: 100%;
    padding-left: 0.9rem;
}

.input {
    all: unset;
    width: 88%;
    height: 100%;
}
.input:focus {
    all: unset;
    width: 88%;
    height: 100%;
}

/*? table */

.actions {
    display: flex;
    justify-content: space-between;
    column-gap: 0.7rem;
    align-items: center;
}

.actions div {
    display: flex;
    column-gap: 0.7rem;
}

.actions div a {
    cursor: pointer;
}

.btnReserva {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 7rem;
    height: 2.2rem;
    border-radius: 5px;
    background-color: transparent;
    color: black !important;
    border: 1px solid rgb(186, 185, 185);
    cursor: pointer;
}

.btnEdit {
    color: #e4701e;
}
.btnEdit:hover {
    color: #e4701e;
}

.btnReserva:active,
.btnReserva:hover {
    background-color: #e4701e;
    color: white;
    border: 1px solid white;
}

.btnReserva:hover {
    color: white;
}

.btnDelete {
    color: #c3102f;
}

.btnDelete:hover {
    color: #a40e27 !important;
}

.cardTitle {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}
.columnDirection {
    flex-direction: column !important;
}

.cardList {
    display: flex;
    column-gap: 0.5rem;
    flex-wrap: wrap;
    row-gap: 1.5rem;
    justify-content: space-between;
    list-style: none;
    padding: 0;
}

.cardList li {
    display: flex;
    justify-content: start;
    align-items: center;
    column-gap: 0.5rem;
}

.cardButton {
    width: 100%;
}

.btnCheckedContainer {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    column-gap: 0.5rem;
}
.btnChecked {
    display: block;
    height: 2rem;
    width: 2rem !important;
    border: 1px solid rgb(212, 211, 211);
    display: flex;
    justify-content: center;
    align-items: center;
}

.btnChecked:hover {
    border: 1px solid rgb(212, 211, 211);
}

/** Reservas */
.inputFileGroup {
    border: 1px dashed;
    position: relative;
}

.inputFileGroup p {
    position: absolute;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 1;
}

.inputFile {
    all: unset;
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
    opacity: 0;
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
}

.selectInput {
    width: 75%;
    text-align-last: left;
    font-size: 16px;
    padding: 0px;
    box-sizing: border-box;
    height: auto;
    line-height: normal;
    display: block;
    margin: auto;
    border: none;
    color: grey;
}

.selected {
    background-color: #098598;
    color: white;
}

/* CALENDARIO */

.calendarCard {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.reservationsCard {
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.reservationItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.reservationItem:hover {
    background-color: #f9f9f9;
}

.reservationItem div {
    margin-right: 10px;
}

.reservationItem button {
    margin-left: auto;
}

.calendarHeader {
    text-align: center;
    font-size: 1.25rem;
    margin-bottom: 10px;
}

.react-calendar {
    width: 100%;
    border: none;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
}

/** Mesas */
.mesa {
    width: 19rem;
    height: 19rem;
    border-radius: 90rem;
    background-color: #009290;
    border: 1px solid white;
    box-shadow: 0px 0px 15px rgb(169, 169, 169);
    color: white;
}

.mesaTitle {
    width: 11rem;
    height: 5.5rem;
    margin: 0 auto;
    border-radius: 90rem 90rem 0 0 !important;
    background-color: transparent;
    color: white !important;
    border-bottom: 1px solid white;
}

.mesaTitle h4 {
    color: white !important;
}

.cardButtonMesa {
    display: block;
    width: 10rem;
    margin: 0 auto;
    border-radius: 45rem;
    background-color: #def1fd;
    border: white;
    color: black;
}

.cardButtonMesa:hover {
    background-color: #def1fd;
    color: black;
}

.cardButtonMesa:active {
    background-color: #d2e3ee !important;
    color: black !important;
}

.mesa.mesa__noDisponible {
    background-color: #c3102f;
}

/* ASIGNACION CAMPOS */
.selectContainer {
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 15px;
}

/* WHATSAPP background-color: #208846;*/
.btnWhatsApp {
    background-color: #208846;
    color: #fff;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btnWhatsApp:hover {
    background-color: #128c7e;
}


/* IMAGEN ZONAS */

.fullScreenOverlay {
    /* background-color: rgba(0, 0, 0, 0.1) !important; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.modalBody {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
}
.fullScreenImage {
    max-width: 95vw; 
    max-height: 95vh; 
    object-fit: contain;
    margin: auto;
}

/* MESAS ASIGNACION */
.mesasGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
    gap: 10px;
    justify-content: center;
  }
  
  .mesaWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .mesaCheckbox {
    display: block;
    width: 60px;
    height: 60px;
    cursor: pointer;
  }
  
  .mesaCheckbox input {
    display: none;
  }
  
  .mesaNumber {
    display: block;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 2px solid #ccc;
    border-radius: 50%;
    transition: all 0.3s;
    font-size: 11px;
    font-weight: bold;
    text-align: center;
    padding: 09px;
  }
  
  .mesaCheckbox input:checked + .mesaNumber {
    background-color: #128c7e;
    color: white;
    border-color: #128c7e;
  }
  
  .mesaOcupada .mesaNumber {
    background-color: #ffebee;
    border-color: #ffcdd2;
    color: #b71c1c;
    cursor: not-allowed;
  }
  
  .ocupadaIndicator {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 10px;
    height: 10px;
    background-color: #f44336;
    border-radius: 50%;
  }


  